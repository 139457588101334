@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 60px !important;
    left: 20px !important;
  }

  .intercom-dfosxs {
    bottom: 60px !important;
    left: 20px !important;
  }

  iframe[name="intercom-notifications-frame"] {
    bottom: 132px !important;
    left: 20px !important;
  }
}
